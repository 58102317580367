.footer {
	background-color: $light-grey;
	padding-top: 30px;

	&__bottom {
		background-color: $dark-grey;
		font-size: 13px;
		line-height: 40px;
	}

	&__logo {
		margin-bottom: 15px;
		width: 170px;
	}
}