.slider {
	margin: 0;
	padding: 0;
	width: 100%;

	img {
		width: 100%;
	}

	&__slide {
		height: 100% !important;
		overflow: hidden;
	    position: relative;	
	}

	&__nav {
		margin-top: 20px;
	  	text-align: center;
	}
	
}


.btn-nav {
	background-color: $dark-grey;
	border: 0;
	border-radius: 13px;
	cursor: pointer;
	height: 13px;
	margin: 0 2px;
	padding: 0;
	width: 13px;

	&.active {
		background-color: $red;
	}
}