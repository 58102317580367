.venue-card {
	background-color: $light-grey;
	padding: 40px 0;

	h3 {
		margin-bottom: 60px;
	}

	.v-slider {
		margin: 0 -10px;
	}
	
	&__item {
		box-shadow: 2px 2px 2px $dark-grey;
		margin-bottom: 20px;
		position: relative;

		&.slide {
			margin: 0 10px;
		}

	    &-image {
	    	background-position: center;
		    background-repeat: no-repeat;
		    background-size: cover;	
		    height: 175px;
		    position: relative;

		    &:after {
		    	background: linear-gradient(transparent,#000);
		    	content: '';
			    display: block;
			    height: 40%;
			    opacity: .6;
			    position: absolute;
			    	bottom: 0;
			    width: 100%;
		    }

		    .heart {
				background-image: url('../images/heart-grey-full.svg'); 
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
				display: inline-block;
				height: 24px;
				position: absolute;
					top: 10px;
					right: 10px;
				width: 30px;
				z-index: 5;

				&:hover {
					background-image: url('../images/heart-red-full.svg'); 
				}

				&.favorite {
					background-image: url('../images/heart-red-full.svg');
				}
			}
	    }

	    &-link {
	    	display: block;
		    position: absolute;
			    left: 0;
			    top: 0;
			    bottom: 0;
		    width: 100%;
		    z-index: 4;
	    }

	    &-title {
	    	color: $white;
	    	font-size: 18px;
	    	position: absolute;
	    		bottom: 5px;
	    		left: 10px;
	    	z-index: 3;
	    }
	}

	&__content {
		background-color: $white;
		padding: 10px;

		&-area {
			font-size: 13px;
			position: relative;

			img {
				margin-right: 5px;
			}
		}
	}
}