.modal {
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: none;
    font-size: 16px;
    height: 100%;
    overflow: auto;    
    padding-top: 100px;
    position: fixed;
        left: 0;
        top: 0;
    width: 100%;
    z-index: 10;

    &__container {
        background-color: $white;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
        margin: auto;
        max-width: 90%;
        position: relative;
        width: 400px;
        animation-name: animatetop;
        animation-duration: 0.4s
    }

    &__header {
        background-color: $dark-blue;
        padding: 3px 0 5px 10px;
        
        img {
            vertical-align: middle;
        }
    }

    &__content {
        padding: 40px;
        text-align: center;

        .btn {
            font-size: 16px;
        }
    }
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}
