.category-list {
	
	&__container {
		position: relative;	
	}

	&__box {
		background-color: $light-grey;	
		margin-top: 50px;
		padding: 40px 20px;
	}
	
	&__list {
		column-count: 2;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				padding-left: 30px;
				position: relative;

				&:before {
					border-style: solid;
					border-width: 7px 0 7px 7px;
					border-color: transparent transparent transparent $red;
					content: '';
					position: absolute;
						left: 0;
						top: 6px;
				}
			}
		}
	}

	&__image {
		box-shadow: 12px 12px 19px #999;
		max-width: 360px;
		position: absolute;
			top: 0;
		z-index: 2;


		&.right {
			right: 140px;
		}

		&.left {
			left: 140px;
		}
	}
}


@include upToDesktop {

	.category-list {

		&__container {
			padding: 0;
		}

		&__box {
			background-color: $white;
			margin-top: 0;
		}

		&__list {
			column-count: 1;
		}	

		&__image {
			box-shadow: none;
			max-width: 100%;
			position: relative;
				top: auto;

			&.right {
				right: auto;
			}

			&.left {
				left: auto;
			}
		}
	}
}