.favorites {
	background-color: $light-grey;

	&__header {
		background-color: $dark-blue;
		color: $white;
		margin-bottom: 30px;

		&-title {
			font-size: 37px;
			margin: 0;
		}

		&__container {
			display: flex;
			align-content: center;
			align-items: center;
			height: 100px;
		}
	}

	&__item {
		background-color: $white;
		margin-bottom: 30px;
		width: 100%;

		&-image {
			background-position: center;
			background-size: cover;
			height: 120px;
			position: relative;

			&-link {
				display: block;
				position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				z-index: 2;
			}
		}

		&-content {
			padding: 20px;

			&-title {
				display: block;
				font-size: 16px;
				font-weight: 900;
			}

			.read-more {
				margin-bottom: 0;

				a {
					color: $red;

					&:hover {
						font-weight: 400;
					}
				}
			}
		}

		&-info {
			color: $grey;
			padding: 20px 10px 20px 0;

			img {
				margin-right: 5px;
				vertical-align: middle;
			}

			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
					display: inline-block;
					width: 68px;

					&.heart {
						background-image: url('../images/heart-red-full.svg'); 
						background-repeat: no-repeat;
						cursor: pointer;
						height: 24px;
						vertical-align: middle;
						width: 30px;

						&:hover {
							background-image: url('../images/heart-grey-full.svg'); 
						}
					}
				}
			}
		}
	}

	&__back {
		padding: 0;
	}
}


@include upToDesktop {
	.favorites {

		&__item {
			margin-bottom: 10px;

			&-image {
				height: 200px;

				&-link {
					background-image: linear-gradient(-1deg, #020713 0%, rgba(255,255,255,0.00) 44%, rgba(0,0,0,0.02) 73%, #1E232F 100%);
					opacity: 0.76;
				}
			}
			
			&-info {
				color: $white;
				font-weight: 900;
				position: absolute;
					bottom: 0;
					right: 0;
				z-index: 3;
			}
			
			.heart {
				background-image: url('../images/heart-red-full.svg'); 
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
				display: inline-block;
				height: 30px;
				position: absolute;
					top: 10px;
					right: 10px;
				width: 35px;
				z-index: 3;

				&:hover {
					background-image: url('../images/heart-grey-full.svg'); 
				}
			}
		}
	}
}