.gform_wrapper {

    *,
    &:after,
    &:before {
        box-sizing: border-box!important;
    }

    h3 {
        font-size: 27px;
        margin-bottom: 5px;
    }

    input {
        background: $white;
        border: 0.5px solid #D2D2D2;
        box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,0.22);
        font-size: 1em;
        padding: 10px;
        width: 100%;
    }

    textarea {
        appearance: none;
        background: $white;
        border: 0.5px solid #D2D2D2;
        box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,0.22);
        font-size: 1em;
        height: 80px;
        padding: 10px;
        width: 100%;
    }

    select {
        appearance: none;        
        background: $white;
        border: 0.5px solid #D2D2D2;
        color: $border-grey;
        width: 100%;
    }

    .validation_error {
        color: #ff0000;
        font-weight: 900;
        line-height: 1.3;
    }

    .validation_message {
        color: #ff0000;
        font-size: 12px;
        font-weight: 900;
    }

    .gfield_html {
        padding-right: 0;
    }

    .ginput_container_select {
        display: inline;
        position: relative;

        &:after {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 8px solid $red;
            content:"";
            height: 0;
            pointer-events: none;
            position: absolute;
                top: .3em;
                right: .75em;
            width: 0;
        }        
    }

    .gform_button {
        background-color: $red;
        border: 0;
        border-radius: 2px;
        box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.80);
        color: $white;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        font-weight: 900;
        line-height: 38px;
        margin-top: 20px;
        padding: 0 30px;
        text-align: center;
        text-decoration: none;        
        width: 100% !important;

        &:hover {
            background-color: $red;
            box-shadow: inset 2px 2px 2px #666;
            color: $white;
        }
    }

    .gf_right_half {
        padding-right: 0;
    }

    ul {
        li {
            list-style: none!important;
            margin-bottom: 10px;
            margin-left: 0!important;
            overflow: visible;
        }

        &.gfield_checkbox, 
        &.gfield_radio {
            margin: 6px 0;
            padding: 0;

            li input, 
            li input[type=checkbox], 
            li input[type=radio] {
                display: -moz-inline-stack;
                display: inline-block;
                margin-left: 1px;
                margin-top: 6px;
                vertical-align: middle;
                width: auto!important;
            }

            li label, 
            li label {
                display: -moz-inline-stack;
                display: inline-block;
                letter-spacing: inherit;
                vertical-align: middle;
                cursor: pointer;
            }
        }

        &.gform_fields {
            display: block;
            list-style-type: none;
            margin: 0;
            overflow: visible;
            padding: 0;

            li {

                .gfield {
                    clear: both;
                    margin-top: 16px;
                    padding-top: 0;
                }
            }
        }
    }

    label {
        display: -moz-inline-stack;
        display: inline-block;
        line-height: 1.3;
        clear: both;
        color: #606060;
        font-size: 13px;
    }
}


@include fromTablet {

    .gform_wrapper {
        .top_label li.gfield.gf_left_half, 
        .top_label li.gfield.gf_right_half {
            display: -moz-inline-stack;
            display: inline-block;
            float: none;
            padding-right: 8px;
            vertical-align: top;        
            width: 50%;
        }

        .top_label li.gfield.gf_right_half {
            padding-left: 8px;
            padding-right: 0;
            float: right;
        }

        .top_label li ul.gfield_checkbox, 
        .top_label li ul.gfield_radio {
            width: calc(100% + 16px);
        }

        ul.gform_fields li {
            &.gfield {
                padding-right: 0;
            }
        }
    }
}
