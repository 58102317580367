select {
    appearance: none;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    overflow: hidden;
    padding: .5em;
    padding: 10px 2em 10px 1em;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::-ms-expand {
        display: none;
    }
}

.select-container {
    display: inline;
    position: relative;

    &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid $red;
        content:"";
        height: 0;
        pointer-events: none;
        position: absolute;
            top: .3em;
            right: .75em;
        width: 0;
    }
}

.searchform {

    &__title {
        font-size: 28px;
    }  

    &__select {
        background: $white;
        border: 1px solid #D2D2D2;
        width: 100%;
    }

    &__input {
        background: $white;
        border: 1px solid #D2D2D2;
        font-size: 1em;
        padding: 10px;
        width: 100%;
    }

    &__submit {
        border-radius: 2px;
        box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.80);
        font-size: 16px;
        margin-top: 20px;
        width: 100% !important;
    }
}

.searchform-wide {
    background: rgba(255,255,255,0.89);
    border: 1px solid #EEEDED;
    box-shadow: 3px 3px 3px 0 rgba(0,0,0,0.50);
    padding: 20px;
    margin-bottom: 30px;

    &__submit {
        font-size: 16px;
        height: 40px;
        margin-top: 26px;
        width: 100% !important;
    }
}