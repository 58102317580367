.two-columns {
	padding: 40px 0;

	h3 {
		margin-bottom: 60px;
	}

	&__content {
		margin-bottom: 20px;
	}
}