.b-container {
    height: 60px;
    left: auto;
    right: 0px;
    top: 0px;
}

.b-menu {
	background: transparent;
	border: 0;
	height: 50px;
	padding-left: 4px;
    padding-top: 10px;	
	width: 50px;
	
	&:hover {
		border: 0;
	}
}

body:after {
    background: $white;
    z-index: 10;
}

.b-nav {
	li {
		&:first-child {
			margin-top: 50px;
			padding-top: 0;
		}
	}
}

.b-bun--mid {
    top: 6px;
}

.b-bun--bottom {
    top: 12px;
}