// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$small-width:		 320px;
$phone-width:        480px;
$tablet-width:       768px;
$desktop-width:      1024px;
$wide-width:         1200px;

$header-height: 80px;