.tax-archive {
	background-color: $light-grey;
	padding: 20px 0;

	&__header {
		background-color: $dark-blue;
		color: $white;

		&-title {
			font-size: 28px;
			margin: 0;
		}

		&__container {
			display: flex;
			align-content: center;
			align-items: center;
			height: 100px;
		}
	}

	&__description {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}