.btn {
	background-color: $dark-blue;
	border: 0;
	color: $white;
	display: inline-block;
	font-weight: 900;
	line-height: 38px;
	padding: 0 30px;
	text-align: center;
	text-decoration: none;

	&.red {
		background-color: $red;
		width: 220px;
	}

	&:hover {
		background-color: $red;
		box-shadow: inset 2px 2px 2px #666;
		color: $white;
	}
}