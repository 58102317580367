.cards {
	margin-top: 25px;

	&__item {
	    background-position: center;
	    background-repeat: no-repeat;
	    background-size: cover;	
	    height: 300px;
	    margin-bottom: 30px;
	    position: relative;

	    &:after {
	    	background: linear-gradient(transparent,#000);
	    	content: '';
		    display: block;
		    height: 40%;
		    opacity: .6;
		    position: absolute;
		    	bottom: 0;
		    width: 100%;
	    }

	    &-link {
	    	display: block;
		    position: absolute;
			    left: 0;
			    top: 0;
			    bottom: 0;
		    width: 100%;
		    z-index: 4;
	    }

	    &-title {
	    	color: $white;
	    	position: absolute;
	    		bottom: 0;
	    		left: 20px;
	    	z-index: 3;
	    }
	}
}