.pagination {
	color: $grey;

	a {
		color: $grey;

		&:hover {
			color: $red;
			font-weight: 400;
		}
	}

	.current {
		color: $red;
	}

	.page-numbers {
		padding: 0 1px;
	}

	.prev {
		display: inline-block;
		margin-right: 10px;
		padding-left: 10px;
		position: relative;
		text-indent: -9999px;

		&:before {
			border-style: solid;
			border-width: 6px 10px 6px 0;
			border-color: transparent $red transparent transparent;
			content: '';
			position: absolute;
				right: 0;
				top: 7px;
		}	
	}

	.next {
		display: inline-block;
		margin-left: 10px;
		padding-right: 10px;
		position: relative;
		text-indent: -9999px;

		&:before {
			border-style: solid;
			border-width: 6px 0 6px 10px;
			border-color: transparent transparent transparent $red;
			content: '';
			position: absolute;
				right: 0;
				top: 7px;
		}	
	}

}