.page-header {
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;	
    height: 550px;
    margin-bottom: 30px;
    margin-top: -$header-height;
    padding-top: $header-height;
    position: relative;

    &:after {
    	background: linear-gradient(#000, transparent);
    	content: '';
	    display: block;
	    height: 60%;
	    opacity: .6;
	    position: absolute;
	    	top: 0;
	    width: 100%;
    }

    &__container {
    	display: flex;
    	align-items: center;
    	vertical-align: center;
    }

    &__box {
    	padding: 30px;
        position: relative;
    	z-index: 2;

        &-background {
            background-color: $light-grey;
            opacity: 0.8;
            position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            z-index: 3;
        }
    }

    &__content {
        position: relative;
        z-index: 4;
    }
}

@include upToDesktop {
    .page-header {
        height: 700px;
    }
}