.venue {

	&__header {
		background-color: $dark-blue;
		color: $white;
		margin-bottom: 30px;

		&-title {
			font-size: 37px;
			margin: 0;
		}

		&__container {
			display: flex;
			align-content: center;
			align-items: center;
			height: 100px;
		}

		&__col {
			flex: 1;


			&:last-child {
				text-align: right;
			}
		}
	}

	&__contact {
		padding-bottom: 30px;
	}

	&__favorite {
		float: right;

		.save-favorites {
			background: url('../images/heart-grey-empty.svg') right no-repeat;
			color: $grey;
			cursor: pointer;
			padding: 5px 40px 5px 0;

			&:hover {
				background: url('../images/heart-red-empty.svg') right no-repeat;
				color: $red;
			}
		}

		.list-favorites {
			background: url('../images/heart-red-full.svg') right no-repeat;
			color: $red;
			cursor: pointer;
			display: none;
			padding: 5px 42px 5px 0;

			a {
				color: $red;

				&:hover {
					font-weight: 400;
				}
			}

			&.show {
				display: inline-block !important;
			}
		}
	}

	&__info {
		background-color: $dark-grey;
		margin-bottom: 20px;
		padding: 30px;

		img {
			vertical-align: middle;
		}	
		
		&-title {
			font-size: 28px;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				display: flex;
				padding: 4px 0;
			}
		}

		&-image {
			margin-right: 15px;
			text-align: center;
			width: 30px;
		}
	}

	&__item {
	    border-top: 1px solid $border-grey;

	    &:last-of-type {
	    	border-bottom: 1px solid $border-grey;
	    	margin-bottom: 20px;
	    }
	}

    &__question {
		cursor: pointer;
		display: flex;
		height: 65px;
		line-height: 65px;
		padding: 0 20px;
		position: relative;
		width: 100%; 

		&.active {
			background-color: $dark-grey;
		}

		&-image {
			margin-right: 15px;
			text-align: center;
			width: 30px;

			img {
				vertical-align: middle;
			}	
		}

		&-text {
			text-align: left;

			img {
				margin-right: 20px;
				vertical-align: middle;
			}
		}

		&-arrow {
			height: 16px;
			line-height: 1.8;
			margin-top: 25px;
    		margin-right: 10px;
    		position: absolute;
    			right: 0;

			&.arrow-right {
				border-style: solid;
				border-width: 8px 0 8px 12px;
				border-color: transparent transparent transparent $red;
				transition: opacity 1s;
			}

			&.arrow-down {
				border-style: solid;
				border-width: 12px 8px 0 8px;
				border-color: $red transparent transparent transparent;
				transition: opacity 1s;
			}
		}
    }	

    &__answer {
		display: none;
		line-height: 1.65;

		@include fromTablet {
			padding-top: 8px;
			padding-bottom: 1px;			
		}

		.active {
			display: block;
		}	
    }	
}

@include upToDesktop {

	.venue {	

		&__contact {
			text-align: center;
		}

		&__favorite {
			float: none;
			margin-top: 10px;

			.list-favorites {
				&.show {
					display: inline-block !important;
				}
			}
		}
	}
}