.partners-list {
	padding-top: 25px;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			margin-bottom: 10px;

			img {
				margin-right: 10px;
				max-width: 25px;
				vertical-align: middle;
			}
		}
	}

	@include fromSmall {
		column-count: 2;

		ul {
			li {
				img {
					margin-right: 15px;
					max-width: 100%;
				}
			}
		}
	}

	@include fromTablet {
		column-count: 3;
	}

	@include upToTablet {
		font-size: 14px;
	}
}