.header {

	height: $header-height;
	padding: 10px 0;
	position: fixed;
		top: 0;
	width: 100%;
	z-index: 99;

	&__container {
		z-index: 1;
	}

	&__row {
		display: flex;
		align-items: center;
	}

	&.dark {
		&:after {
			background-color: #fff;
			border-bottom: 1px solid $light-grey;
			content: '';
			height: $header-height;
			position: absolute;
				top: 0;
			width: 100%;
			z-index: 0;
		}

		a {
			color: $black;
		}

		.b-bun {
			background-color: $black;
		}
		
	}

	&__nav {
		text-align: right;
		z-index: 2;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			text-align: right;

			li {
				display: inline-block;

				a {
					color: $white;
					font-weight: 300;
					padding-left: 15px;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__mobile {
		position: absolute;
			top: 10px;
			right: 0;
		width: 100%;
		z-index: 10;

		a {
			color: $white;
			display: block;
			padding-left: 15px;
			text-decoration: none;

			&:before {
				border-style: solid;
				border-width: 7px 0 7px 7px;
				border-color: transparent transparent transparent $red;
				content: '';
				position: absolute;
					left: 0;
					top: 6px;
			}
		}
	}
}